import styled from '@emotion/styled';
import { Link } from '@pafcloud/base-components';
import { BaseFont, Color, deviceWithHover, FontTextSize } from '@pafcloud/style';

export const HeaderMenuLink = styled(Link)({
  position: 'relative',
  color: Color.BodyText,
  fontFamily: BaseFont.BrandFont,
  fontSize: FontTextSize.Normal,
  textTransform: 'uppercase',
  textShadow: '0 2px 0 rgba(0,0,0,0.5)',

  '&[aria-current="true"]': {
    color: Color.Primitive.Primary,
  },

  [deviceWithHover]: {
    '&:not([aria-current="true"]):hover': {
      color: Color.HighlightedText,
    },
  },
});
